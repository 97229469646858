




































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'
import UploadImage from '@/components/UploadImage.vue'
import moment from 'moment'

interface DataExportForm {
  export_time: string
  date_from: string
  date_to: string
  last_days: any
}

interface DateButtons {
  text: string
  value: number
}

interface SearchParams {
  page: number
  count: number
}

interface AddInventoryForm {
  image_urls: string[]
  remark: string
}

@Component({
  components: {
    HeaderBox,
    UploadImage
  }
})
export default class DailyInventoryReport extends Vue {
  loading: boolean = false
  // uploadUrl: string = '/api/v1/source_handler/upload/image/inventory'
  dataExportDialogVisible: boolean = false
  addInventoryDialogVisible: boolean = false
  tableData: object[] = []
  rules = {
    image_urls: {
      required: true,
      message: '请上传图片',
      trigger: 'blur'
    },
  }
  dateButtons: DateButtons[] = [{
    text: '今天',
    value: 0,
  }, {
    text: '近7天',
    value: 7,
  }, {
    text: '近30天',
    value: 30,
  }, {
    text: '近90天',
    value: 90,
  }]
  addInventoryForm: AddInventoryForm = {
    image_urls: [],
    remark: ''
  }
  dataExportForm: DataExportForm = {
    export_time: '',
    date_from: '',
    date_to: '',
    last_days: 0
  }
  searchParams: SearchParams = {
    page: 1,
    count: 10
  }
  totalNum: number = 0
  isTapDone: boolean = false

  created () {
    this.getInventoryList();
  }

  handleGo (path, params = null) {
    const { href } = this.$router.resolve({ path, query: params });
    window.open(href, '_blank');
  }

  async getInventoryList () {
    this.loading = true;
    const { code, data } = await this.$rest.dataCenter.getInventoryList(this.searchParams);
    this.loading = false;
    if (code !== 0) return;
    this.tableData = data.infos;
    this.totalNum = data.total_count;
  }

  getDate (days) {
    const lw: any= new Date((new Date() as any) - 1000 * 60 * 60 * 24 * days);
    const lastY = lw.getFullYear();
    const lastM = lw.getMonth()+1;
    const lastD = lw.getDate();
    const startdate = lastY+"-"+(lastM < 10 ? "0" + lastM : lastM)+"-"+(lastD<10 ? "0"+ lastD : lastD);
    return startdate
  }

  handleSetTime (value) {
    this.dataExportForm.last_days = '';
    this.dataExportForm.date_from = value && value[0];
    this.dataExportForm.date_to = value && value[1];
  }

  listenChildImage (image_urls) {
    this.addInventoryForm.image_urls = image_urls;
  }

  async handleDataExportOk (date = '') {
    if (date) {
      this.dataExportForm.date_from = date;
      this.dataExportForm.date_to = date;
    } else {
      delete this.dataExportForm.export_time;
      const days = moment(this.dataExportForm.date_to).diff(moment(this.dataExportForm.date_from), 'days') + 1;
      if (days > 90) {
        this.$message.error('最多只能导出90天的数据');
        return;
      }
      if (!this.dataExportForm.date_from ||  !this.dataExportForm.date_to) {
        this.dataExportForm.date_from = this.getDate(this.dataExportForm.last_days);
        this.dataExportForm.date_to = this.getDate(0);
      }
    }
    this.isTapDone = true;
    const { code } = await this.$rest.dataCenter.postExportRecord({
      start_date: this.dataExportForm.date_from,
      end_date: this.dataExportForm.date_to
    })
    this.isTapDone = false;
    if (code === 0) {
      this.dataExportDialogVisible = false;
      this.handleGo('/dataCenter/exportRecord', this.dataExportForm)
    }
  }

  handleExport (date) {
    this.handleDataExportOk(date);
  }

  handleSelDayChange () {
    this.dataExportForm.export_time = ''
    this.dataExportForm.date_from = '';
    this.dataExportForm.date_to = '';
  }

  validateParams() {
    let isValid = false
    const addInventoryFrom: any = this.$refs.addInventoryFrom
    addInventoryFrom.validate(res => {
      isValid = res
    })
    return isValid
  }

  async handleAddInventoryOk () {
    if (this.addInventoryForm.remark.length > 200) {
      this.$message.error('内容长度不能超过200个字');
      return;
    }
    if (!this.validateParams()) {
      return;
    }
    let form: any = Object.assign({}, this.addInventoryForm);
    form.image_urls = this.formatImages(form.image_urls);
    this.isTapDone = true
    const { code } = await this.$rest.dataCenter.postInventoryTrace(form);
    this.isTapDone = false
    if (code !== 0) return;
    this.addInventoryDialogVisible = false;
    this.getInventoryList();
    this.$message.success('新增成功');
  }

  handleCurrentChange () {
    this.getInventoryList();
  }

  @Watch('addInventoryDialogVisible')
  showAddInventoryDialogVisible () {
    this.addInventoryForm.image_urls = [];
    this.addInventoryForm.remark = '';
    if (!this.addInventoryDialogVisible) {
      (this.$refs['addInventoryFrom'] as any).resetFields();
    }
  }
}
